import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import ReactMarkdown from "react-markdown/with-html";
import useSetPageData from "../utils/useSetPageData";
import { LinkRenderer } from "../utils/utilities";
import Video from "../components/non-panels/react-modal-video/normal-video";
import scrollTo from "gatsby-plugin-smoothscroll";

import {
  formatUTCStringToLocalDateForPost,
  isDateInFutureOrToday,
  exists
} from "../utils/utilities";

//components
import PostItem from "./posts/PostItem";
import LinkBar from "./posts/LinkBar";
import SEO from "../components/seo";

//styles
import s from "./PostView.module.scss";
import postItemStyles from "./posts/PostItem.module.scss";

function extractPostData({ edges }) {
  return edges
    .sort((a, b) => {
      let date1 = a.node["publish_date"] || a.node["created_at"];
      let date2 = b.node["publish_date"] || b.node["created_at"];
      return date1 - date2;
    })
    .map(({ node }) => node);
}

export default function PostView({
  data,
  pageContext: { slugToURIMap, queryName },
  location
}) {
  let postQuery;
  let recentPostsQuery;

  switch (queryName.trim()) {
    case "allStrapiPostBlog":
      postQuery = "pageBlogPost";
      recentPostsQuery = "recentBlogPosts";
      break;

    // case "allStrapiPostMedias":
    //   postQuery = "pageMediaPost";
    //   recentPostsQuery = "recentMediaPosts";
    //   break;

    case "allStrapiPostNews":
      postQuery = "pageNewsPost";
      recentPostsQuery = "recentNewsPosts";
      break;

    case "allStrapiPostWebinar":
      postQuery = "pageWebinarPost";
      recentPostsQuery = "recentWebinarPosts";
      break;

    case "allStrapiPostWhitePapers":
      postQuery = "pageWhitePaperPost";
      recentPostsQuery = "recentWhitePaperPosts";
      break;

    case "allStrapiPostCase":
      postQuery = "pageCaseStudiesPost";
      recentPostsQuery = "recentCaseStudiesPosts";
      break;
  }

  const [post, recentPostList] = [data[postQuery], data[recentPostsQuery]].map(
    extractPostData
  );

  let { slug, heading, created_at, content, SEO: seo } = post[0]; // Common/Mandatory
  let { event_start_date, event_end_date, publish_date } = post[0]; // Media-specific(may not be present.)
  let { link } = post[0];

  useSetPageData(location);

  if (postQuery === "pageCaseStudiesPost") {
    content =
      "\n### Overview\n" +
      post[0].overview +
      "\n### Challenge\n" +
      post[0].challenge +
      "\n### Solution\n" +
      post[0].solution +
      "\n### Benefits\n" +
      post[0].benefits;
  }

  let image;
  let ogUrl;
  let postTitle;
  let isDateInFutureOrTodayResult;
  // Extract appropriate image (Blog/Media.)
  if (post[0].blog_cover_image) {
    image = post[0].blog_cover_image.childImageSharp.fluid;
    ogUrl = post[0].blog_cover_image.publicURL;
    postTitle = "Blog";
  }
  if (post[0].media_image) {
    image = post[0].media_image.childImageSharp.fluid;
    ogUrl = post[0].media_image.publicURL;
    postTitle = "News";
  }
  if (post[0].webinar_image) {
    image = post[0].webinar_image.childImageSharp.fluid;
    ogUrl = post[0].webinar_image.publicURL;
    isDateInFutureOrTodayResult = isDateInFutureOrToday(post[0].created_at);
    postTitle = "Webinar";
  }
  if (post[0].case_image) {
    image = post[0].case_image.childImageSharp.fluid;
    ogUrl = post[0].case_image.publicURL;
    postTitle = "Case Studies";
  }

  // let date = publish_date || created_at;
  // date = formatUTCStringToLocalDateForPost(date);
  useEffect(() => {
    // Temporary solution to scroll to topics
    if (
      post[0] &&
      post[0].heading === "4 Outstanding Deep Learning Applications in Business"
    ) {
      let bankingIndustry = document.getElementById("Banking Industry");
      let manufacturingIndustry = document.getElementById(
        "Manufacturing Industry"
      );
      let pharmaceuticalIndustry = document.getElementById(
        "Pharmaceutical Industry"
      );
      let OilAndGasIndustry = document.getElementById("Oil and Gas industry");

      bankingIndustry.addEventListener("click", () =>
        scrollTo("#DeepLearninginFinanceandBanking")
      );
      manufacturingIndustry.addEventListener("click", () =>
        scrollTo("#DeepLearningApplicationsinManufacturing")
      );
      pharmaceuticalIndustry.addEventListener("click", () =>
        scrollTo("#DeepLearninginPharmaceuticals")
      );
      OilAndGasIndustry.addEventListener("click", () =>
        scrollTo("#DeepLearninginOilandGas")
      );
    }

    return () => {
      if (
        post[0] &&
        post[0].heading ===
          "4 Outstanding Deep Learning Applications in Business"
      ) {
        let bankingIndustry = document.getElementById("Banking Industry");
        let manufacturingIndustry = document.getElementById(
          "Manufacturing Industry"
        );
        let pharmaceuticalIndustry = document.getElementById(
          "Pharmaceutical Industry"
        );
        let OilAndGasIndustry = document.getElementById("Oil and Gas industry");

        bankingIndustry.removeEventListener("click", () =>
          scrollTo("#DeepLearninginFinanceandBanking")
        );
        manufacturingIndustry.removeEventListener("click", () =>
          scrollTo("#DeepLearningApplicationsinManufacturing")
        );
        pharmaceuticalIndustry.removeEventListener("click", () =>
          scrollTo("#DeepLearninginPharmaceuticals")
        );
        OilAndGasIndustry.removeEventListener("click", () =>
          scrollTo("#DeepLearninginOilandGas")
        );
      }
    };
  }, []);

  // console.log("post[0]", post[0]);
  return (
    <>
      <SEO
        {...{
          title: `${post[0].SEO.tab_title} | Insights`,
          description: seo.description
        }}
        meta={ogUrl ? [{ property: "og:image", content: ogUrl }] : []}
      />
      <div className={`container-fluid panel-padding`}>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-sm-12 mb-5`}>
              {/* <h2 className={`text-center text-secondary m-auto`}>{postTitle}</h2> */}
              <h1 className={`${s.mainHeading} text-primary`}>
                <span className={`text-secondary`}>{postTitle}</span> -{" "}
                {post[0].heading}
              </h1>
            </div>
            <div className={`col-lg-8  mb-12 mb-5`}>
              {post[0].webinar_image && isDateInFutureOrTodayResult && (
                <>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <Image className={postItemStyles.postImage} fluid={image} />
                  </a>
                </>
              )}
              {post[0].webinar_image && !isDateInFutureOrTodayResult && (
                <Image className={postItemStyles.postImage} fluid={image} />
              )}
              {!post[0].webinar_image && (
                <Image className={postItemStyles.postImage} fluid={image} />
              )}

              {/* {link && (
                <>
                  <a
                    href={link}
                    className={`btn btn-primary ${s.registerButton} mt-3`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Register
                  </a>
                  <br />
                </>
              )} */}

              {postQuery === "pageNewsPost" && (
                <div className={`pt-3`}>
                  {event_start_date || event_end_date ? (
                    <p>
                      Date:{" "}
                      <strong>
                        {formatUTCStringToLocalDateForPost(
                          event_start_date || event_end_date
                        )}
                        {event_start_date && event_end_date ? (
                          <span>
                            {" "}
                            -{" "}
                            {formatUTCStringToLocalDateForPost(event_end_date)}
                          </span>
                        ) : null}
                      </strong>
                    </p>
                  ) : null}
                  {post[0].location ? (
                    <p>
                      Location: <strong>{post[0].location}</strong>
                    </p>
                  ) : null}
                </div>
              )}
              {post[0].webinar_image && link && isDateInFutureOrTodayResult && (
                <>
                  <br />
                  <a
                    href={link}
                    className={`btn btn-secondary  btn-lg ${s.registerButton}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {/* <i class="fas fa-clipboard-check mr-3"></i> */}
                    REGISTER
                    <i class="fas fa-play-circle ml-3"></i>
                  </a>
                </>
              )}

              <ReactMarkdown
                id="anId"
                className={`${postItemStyles.postContent} ${postItemStyles.newView} pt-5`}
                source={content}
                renderers={{ link: LinkRenderer }}
                escapeHtml={false} // incase we need to add class names in markup on strapi.
              />
              {post[0].webinar_image && link && isDateInFutureOrTodayResult && (
                <>
                  <a
                    href={link}
                    className={`btn btn-secondary btn-lg ${s.registerButton}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    REGISTER
                    <i class="fas fa-play-circle ml-3"></i>
                  </a>
                </>
              )}
              {post[0].webinar_image &&
                !post[0].video_list.length &&
                !isDateInFutureOrTodayResult && (
                  <h4>No video present. Please check back later.</h4>
                )}
              {(post[0].webinar_image &&
                post[0].video_list &&
                post[0].video_list.length &&
                !isDateInFutureOrTodayResult &&
                post[0].video_list
                  .filter(v => v.has_no_video)
                  .map(v => {
                    return (
                      <h4>
                        No video for this webinar. Check out our{" "}
                        <a href="https://www.youtube.com/channel/UChm0AxXJl4gsIiUimX1kh-A">
                          YOUTUBE
                        </a>{" "}
                        for more videos.
                      </h4>
                    );
                  })) ||
                ""}

              {(post[0].webinar_image &&
                post[0].video_list &&
                post[0].video_list.length &&
                !isDateInFutureOrTodayResult &&
                post[0].video_list
                  .filter(v => v.vimeo_manage_url)
                  .map(v => {
                    return (
                      <>
                        <Video
                          channel={"vimeo"}
                          ratio="16:9"
                          allowFullScreen={true}
                          videoId={v.vimeo_manage_url.split("/")[5]}
                        />
                        <br />
                        <br />
                      </>
                    );
                  })) ||
                ""}

              {/* <PostItem post={post[0]} fullArticle /> */}
            </div>
            <div className={`col-lg-4`}>
              <LinkBar
                URIMap={slugToURIMap}
                recentPosts={recentPostList}
                downloadableFile={
                  post[0].downloadable_file
                    ? post[0].downloadable_file.publicURL
                    : null
                }
              />
            </div>
            {/* <p>Written on: <em>{date}</em></p> */}
          </div>
        </div>
      </div>
    </>
  );
}

export const postQuery = graphql`
  query postQuery($id: String!, $recentLimit: Int!) {
    pageBlogPost: allStrapiPostBlog(filter: { id: { eq: $id } }) {
      edges {
        node {
          heading
          short_description
          id
          slug
          created_at
          content
          blog_publish_date
          publish_date
          blog_cover_image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          SEO {
            tab_title
            description
          }
        }
      }
    }
    recentBlogPosts: allStrapiPostBlog(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
      filter: { published: { eq: true } }
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at
          blog_publish_date
          publish_date
        }
      }
    }
    pageNewsPost: allStrapiPostNews(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          slug
          heading
          content
          event_start_date
          event_end_date
          location
          media_image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          SEO {
            tab_title
            description
          }
          created_at
        }
      }
    }
    recentNewsPosts: allStrapiPostNews(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
      filter: { published: { eq: true } }
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at
          location
          event_start_date
          event_end_date
          publish_date
        }
      }
    }

    pageWebinarPost: allStrapiPostWebinar(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          slug
          heading
          content: description
          created_at: webinar_time
          link
          webinar_image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          video_list {
            has_no_video
            vimeo_manage_url
          }
          SEO {
            tab_title
            description
          }
          publish_date
        }
      }
    }
    recentWebinarPosts: allStrapiPostWebinar(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
      filter: { published: { eq: true } }
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at: webinar_time
          content: description
        }
      }
    }

    pageWhitePaperPost: allStrapiPostWhitePapers(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          slug
          heading
          content
          created_at
          publish_date
          downloadable_file_from_form {
            downloadable_file {
              publicURL
            }
            sales_pitch_one_liner
          }
          white_papers_media_image {
            publicURL
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          SEO {
            tab_title
            description
          }
        }
      }
    }
    recentWhitePaperPosts: allStrapiPostWhitePapers(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
      filter: { published: { eq: true } }
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at
          content
          publish_date
        }
      }
    }

    pageCaseStudiesPost: allStrapiPostCase(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          heading
          overview
          challenge
          solution
          benefits
          created_at
          slug
          case_image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          SEO {
            tab_title
            description
          }
        }
      }
    }
    recentCaseStudiesPosts: allStrapiPostCase(
      sort: { fields: [created_at], order: DESC }
      limit: $recentLimit
      filter: { published: { eq: true } }
    ) {
      edges {
        node {
          heading
          id
          slug
          created_at
        }
      }
    }
  }
`;
